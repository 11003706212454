import React from 'react';

export default function TheEnd() {
  return (
    <div className='ml-4 mt-4'>
      <p className='mb-4'>
        <img src='Images/TheEnd.gif' alt='' />
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          The era of the Original Heathkits came to an end on January 31, 1955,
          when The Heath Company was purchased by Daystrom, Inc. Helen C.
          Anthony sent out a letter announcing the sale coupled with a letter
          from Thomas Roy Jones of Daystrom, Inc. It is interesting to note that
          the Heath leterhead of the Helen C. Anthony announcement already
          carries the tag line &quot;A Subsidiary of Daystrom, Inc.&quot;
        </font>
      </p>
      <p align='left'>&nbsp;</p>
      <p align='left'>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          <a href='Images/SaleLetter1.gif'>Vie</a>
        </font>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          <a href='Images/SaleLetter1.gif'>w the Helen C. Anthony letter </a>
        </font>
        <a href='Images/SaleLetter1.gif'>
          <img
            src='Images/SaleLetter1TN.gif'
            width='87'
            height='113'
            border='0'
            alt=''
          />
        </a>
      </p>
      <p align='left'>&nbsp;</p>
      <p align='left'>
        <a href='Images/SaleLetter2.gif'>
          <font face='Verdana, Arial, Helvetica, sans-serif'></font>
        </a>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          <a href='Images/SaleLetter2.gif'>View the Thomas Roy Jones letter </a>
        </font>
        <a href='Images/SaleLetter2.gif'>
          <img
            src='Images/SaleLetter2TN.gif'
            width='87'
            height='113'
            border='0'
            alt=''
          />
        </a>
      </p>
    </div>
  );
}
