import React from 'react';

export default function Characteristics() {
  return (
    <div className='ml-4 mt-4'>
      <p className='mb-4'>
        <img src='Images/Characteristics.gif' alt='' />
      </p>
      <p align='left'>
        <font face='Arial, Helvetica, sans-serif'>
          The information presented here is to help you determine approximately
          when a particular kit was produced. This is fairly easy for the early
          kits as there were few changes to them during their production period.
          However, some of the later kits enjoyed longer production runs and
          incurred changes in style over the period they were produced.{' '}
        </font>
      </p>
      <p align='left'>
        <font face='Arial, Helvetica, sans-serif'>
          The data in <a href='KitList.asp'>The Kit List</a> will help you to
          tell during what years the model could have been made. The information
          here regarding styling changes will help you to narrow down the date.
          As there were no serial numbers on any of these kits, it is not
          possible to determine exactly when a kit was manufactured or sold.
        </font>
      </p>
      <p align='left'>
        <font face='Arial, Helvetica, sans-serif'>
          Most original Heathkits have a gray panel with maroon lettering and
          are housed in a gray, crinkle-finished metal case with streamline
          aluminum handles. The first cases were very &quot;square&quot; edged
          and the panels were made from flat metal stock. The only way found so
          far to narrow down the production date of some of these models is by
          the type of power or selector switch that was used. The first kits
          used &quot;toggle&quot; switches, but about April, 1949 Heath changed
          to using less expensive &quot;slide&quot; switches.
        </font>
      </p>
      <p align='left'>
        <font face='Arial, Helvetica, sans-serif'>
          The original styling was used until the introduction of the{' '}
          <a href='Images/V-5.jpg'>V-5 VTVM</a>
          in September, 1951. This marked the beginning of the change-over to
          the formed aluminum style of chassis, which helped Heath keep the
          prices of the kits down. The VTVM was an obvious first candidate as it
          was the best selling of the kits and offered the quickest savings. The
          other kits' styling was not changed until the introduction of newer
          models (with different model numbers).
        </font>
      </p>
      <p align='left'>&nbsp;</p>
      <p align='center'>
        <font face='Arial, Helvetica, sans-serif'>
          As you can see, we are still in the process of assembling this site...
        </font>
      </p>
      <p align='center'>&nbsp;</p>
      <p align='center'>
        <img src='Images/Assembly.gif' width='395' height='481' alt='' />
      </p>
    </div>
  );
}
