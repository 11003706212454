import React from 'react';

export default function Home() {
  return (
    <div className='ml-4 mt-4'>
      <p className='mb-4'>
        <img src='Images/Home.gif' alt='' />
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif' size='4'>
          Welcome to the home of the original Heathkits.
          <br />
          <br />
          What are original Heathkits? Original Heathkits are those electronic
          kits sold by The Heath Company between 1947 and 1955, before the
          company was sold to Daystrom, Inc. We cover the era of the original
          kits between <a href='Beginning.htm'>The Beginning</a> and{' '}
          <a href='TheEnd.htm'>The End</a>.<br />
          <br />
          Check out the{' '}
          <a href='HHistory.htm' target='MainFrame'>
            Heath History
          </a>{' '}
          to learn more about the company that started a revolution in the
          hobby, ham radio, and commercial electronics market.
          <br />
          <br />
          <a href='KitList.asp' target='MainFrame'>
            The Kit List
          </a>{' '}
          is a compilation of the original kits that were produced by The Heath
          Company.
          <br />
          <br />
          <a href='Assembly.htm' target='MainFrame'>
            Characteristics
          </a>{' '}
          outlines the evolution of the styles and characteristics of the kits
          over the years, with useful information to help you narrow down the
          exact period when your kit was manufactured.
        </font>
      </p>
      <hr />
      <div align='center'>
        <font size='1' face='Arial, Helvetica, sans-serif'>
          This site and all of its contents are copyright &copy; 2022 by
          PerCentage Corporation.
          <br />
          Send any comments to{' '}
          <a href='mailto:heathkit@percentage.com'>heathkit@percentage.com</a>
        </font>
      </div>
      <p align='center'>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
}
