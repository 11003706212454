import React from 'react';

export default function Beginning() {
  return (
    <div className='ml-4 mt-4'>
      <p className='mb-4'>
        <img src='Images/Beginning.gif' alt='' />
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          In the beginning, The Heath Company did not have anything to do with
          electronic kits. What it actually had to do with was kit airplanes.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          The E. B. Heath Aerial Vehicle Company was founded in Chicago by
          Edward Bayard Heath in the early 1900s. The company designed and sold
          airplanes and parts for airplane kits. Ed Heath was a airplane racing
          pilot and designer. His most notable design, the &quot;Baby
          Bullet&quot; is still in use today.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          Ed Heath was killed in 1931 while testing a prototype airplane they
          were designing for a client. After Ed Heath's death, the company was
          moved to Niles, Michigan for economic and tax reasons. The company's
          financial condition never recovered and it finally went bankrupt in
          1935.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          While the company was in Michigan it had built a racing airplane for a
          Howard Anthony. It was Anthony who ended up buying the company after
          its bankruptcy, for a rumored $300 of his wife's money.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          There is some controversy over the evolution of the company name to
          &quot;The Heath Company.&quot; Apparently, the name of the company had
          been changed to the &quot;International Aircraft Corporation&quot;
          after Ed Heath's death. Also, the company seems to have been called
          &quot;Anthony Aircraft&quot; for a short time after Howard Anthony
          acquired it. However, the name was changed to &quot;The Heath
          Company&quot; soon thereafter, probably because of the recognition the
          name had in the airplane business.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          After Howard Anthony bought the company, he sold the surplus parts
          that came with the assets and continued to design and sell airplane
          parts and kits. During W.W.II, the company sold aircraft parts to the
          government. After the war, they primarily sold aircraft windshields
          and airplane floats, although they did apparently manufacture small
          radios for light planes both during and after the war. They also
          converted surplus government airplanes to civilian use and sold them.
        </font>
      </p>
      <p>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          After the end of the war, and government war contracts, the company
          was again economically shaky. Howard got the idea of buying and
          reselling war surplus electronics parts from friends. In 1947 he got
          an equipment lot that contained hundreds of 5BP1 cathode ray tubes.
          This lead to the idea of making oscilloscopes out of them and selling
          them as kits. This was the major turning point in the history of The
          Heath Company.
        </font>
      </p>
      <p>&nbsp;</p>
    </div>
  );
}
