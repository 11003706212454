import React from 'react';
import {useNavigate} from 'react-router-dom';

export default function Contents() {
  const nav = useNavigate();
  const pClass = 'pt-8 flex justify-center';
  
  return (
    <div className='h-full items-center border-r-4 border-black'>
      <p className='pt-3 pb-4 flex justify-center'>
        <img src='Images/Contents.gif' alt='' />{' '}
      </p>
      <hr className='mx-4' />
      <p className={pClass} onClick={()=>nav('/')}>
          <img src='Images/Home.gif' border='0' alt='' />
      </p>
      <p className={pClass} onClick={()=>nav('/beginning')}>
          <img src='Images/Beginning.gif' border='0' alt='' /> </p>
      <p className={pClass} onClick={()=>nav('/history')}>
          <img src='Images/HHistory.gif' width='127' height='24' border='0' alt='' />
      </p>
      <p className={pClass} onClick={()=>nav('/kitlist')}>
          <img src='Images/KitList.gif' alt='' /> </p>
      <p className={pClass} onClick={()=>nav('/characteristics')}>
          <img src='Images/Characteristics.gif' alt='' /> </p>
      <p className={pClass} onClick={()=>nav('/theend')}>
          <img src='Images/TheEnd.gif' alt='' /> <br />
      </p>
    </div>
  );
}
