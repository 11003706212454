import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Beginning from './Beginning.jsx';
import History from './History.jsx';
import KitList from './KitList.jsx';
import Characteristics from './Characteristics.jsx';
import TheEnd from './TheEnd.jsx';
import Header from './Header';
import Contents from './Contents.jsx';

function App() {
  return (
    <div className="w-[960px] h-full">
      <Header />
      <div className='h-full w-full flex flex-row' >
      <div className='w-2/12 h-full'>
        <Contents />
      </div>
      <div className='w-10/12 h-full'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/beginning' element={<Beginning />} />
          <Route path='/history' element={<History />} />
          <Route path='/kitlist' element={<KitList />} />
          <Route path='/characteristics' element={<Characteristics />} />
          <Route path='/theend' element={<TheEnd />} />
          <Route path='*' element={<Home />} />
        </Routes>
      </div>
      </div>

    </div>
  );
}

export default App;
