import React from 'react';

export default function History() {
  return (
    <div className='ml-4 mt-4'>
      <p className='mb-4'>
        <img src='Images/HHistory.gif' alt='' />
      </p>
      <p align='left'>
        <font face='Verdana, Arial, Helvetica, sans-serif' size='3'>
          In 1947 The Heath Company, which was at that time buying and reselling
          war surplus aircraft and electronics equipment, purchased an equipment
          lot that contained hundreds of 5BP1 cathode ray tubes. The owner of
          the company, Howard Anthony, got the idea of using the CRTs as the
          basis of an oscilloscope kit that could be sold at a very inexpensive
          price. In the summer of 1947, the company placed an ad in a popular
          electronics magazine called &quot;Radio News&quot; advertising the
          oscilloscope kit (model O-1) for sale for $39.50.{' '}
        </font>
        <font face='Verdana, Arial, Helvetica, sans-serif' size='3'>
          Orders began comming in immediately. The success encouraged Anthony to
          create other kits, and in late 1947 he rehired an electronics designer
          that earlier worked for him designing aircraft radios.
        </font>
      </p>
      <p align='left'>
        <font face='Verdana, Arial, Helvetica, sans-serif' size='3'>
          The second kit created was a Vacuum Tube Volt Meter (VTVM) (model
          VT-1). The third kit was for a Radio Frequency (RF) Signal Generator
          (model G-1). By mid 1948 there were also kits for a Sine and Square
          Wave Audio Generator kit (model G-2), a Signal Tracer kit (model T-1)
          and a Condenser Checker kit (model C-1).
        </font>
      </p>
      <p align='left'>&nbsp;</p>
      <p align='center'>
        <font face='Verdana, Arial, Helvetica, sans-serif'>
          As you can see, we are still in the process of assembling this site...
        </font>
      </p>
      <p align='center'>&nbsp;</p>
      <p align='center'>
        <img src='Images/Assembly.gif' width='395' height='481' alt='' />
      </p>
    </div>
  );
}
