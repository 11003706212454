import React from 'react';
import './kitlist.css';
import Heathkits from './KitList.json';

export default function KitList() {
  function sort(col) {}

  function formatDate(strDate) {
    if (!strDate) return '';
    var d = new Date(strDate).toDateString();
    //console.log('dateString', strDate, d);
    var strOutput = d.substring(4, 8);
    strOutput += d.substring(11);
    //console.log('date', strOutput);

    return strOutput;
  }

  function currency(anynum) {
    //-- Returns passed number as string in $xxx,xxx.xx format.
    if (!anynum) return '';
    anynum=Number(anynum)
    if (anynum<0.01) return " ";
 
    let workNum=Math.abs((Math.round(anynum*100)/100));
    let workStr=""+workNum
    if (workStr.indexOf(".")===-1){workStr+=".00"}
    let dStr=workStr.substring(0,workStr.indexOf("."));
    let dNum=dStr-0
    let pStr=workStr.substring(workStr.indexOf("."))
    while (pStr.length<3){pStr+="0"}
 
    //--- Adds comma in thousands place.
    if (dNum>=1000) {
       let dLen=dStr.length
       dStr=parseInt(""+(dNum/1000))+","+dStr.substring(dLen-3,dLen)
    }
 
    //-- Adds comma in millions place.
    if (dNum>=1000000) {
       let dLen=dStr.length
       dStr=parseInt(""+(dNum/1000000))+","+dStr.substring(dLen-7,dLen)
    }
    let retval = dStr + pStr 
    //-- Put numbers in parentheses if negative.
    if (anynum<0) {retval="("+retval+")"}
    return "$"+retval
 }
 

  return (
    <div>
      <div className='bg-purple-600 text-white px-2 pb-2'>
        <div className='text-2xl font-bold py-2'>
            Original Heathkit Models
        </div>
        <p className='text-sm'>
          This list is under construction. If you have any information abuout
          missing items please send it to{' '}
          <a href='mailto:heathkit@percentage.com'>HeathKit@PerCentage.com</a>
          <br />
          Note that Heath did not put model numbers in their early
          advertisments. Model numbers ending in an asterisk indicate my best
          guess as to the model number for a particular kit.
        </p>
      </div>
      <div>
        <table className='w-full'>
          <thead className='h-10'>
            <tr>
              <td className='header w-[10%] text-center'>
                <div onClick={() => sort('model')}>Model</div>
              </td>
              <td className='header'>
                <div onClick={() => sort('description')}>
                  Description
                  <br />
                </div>
              </td>
              <td className='header w-[10%] text-center'>
                <div onClick={() => sort('idate')}>
                  Date
                  <br />
                  Introduced
                </div>
              </td>
              <td className='header w-[10%] text-center'>
                <div onClick={() => sort('price')}>
                  Original
                  <br />
                  Price
                </div>
              </td>
              <td className='header w-[10%] text-center'>
                <div onClick={() => sort('weight')}>
                  Ship
                  <br />
                  Weight
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {Heathkits.map((kit,id) => (
              <tr key={id} className={'' + (id%2===0?'':'bg-green-300')}>
                <td>
                  <div className='row text-center'>{kit.Model}</div>
                </td>
                <td>
                  <div className='row'>{kit.Description}</div>
                </td>
                <td>
                  <div className='row text-center'>{formatDate(kit.DateIntroduced)}</div>
                </td>
                <td>
                  <div className='row text-center'>{currency(kit.OriginalPrice)}</div>
                </td>
                <td>
                  <div className='row text-center'>{kit.ShippingWeight}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
