import React from 'react';

export default function Header() {
  return (
    <div className='flex justify-center border-b-4 border-black'>
      <p className='py-4'>
        <img
          src='Images/SiteTitle.gif'
          align='center'
          width='570'
          height='49'
          alt=''
        />
        <br />
        <img
          src='Images/WorldsFinest.gif'
          width='569'
          height='29'
          alt=''
        />{' '}
      </p>
    </div>
  );
}
